@font-face{
	font-family: devlogo;
	font-display: swap;
	src: url("../fonts/devlogo.woff2") format("woff2"), url("../fonts/devlogo.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: GolosText;
	font-display: swap;
	src: url("../fonts/GolosText-Bold.woff2") format("woff2"), url("../fonts/GolosText-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face{
	font-family: GolosText;
	font-display: swap;
	src: url("../fonts/GolosText-Medium.woff2") format("woff2"), url("../fonts/GolosText-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
@font-face{
	font-family: GolosText;
	font-display: swap;
	src: url("../fonts/GolosText-Regular.woff2") format("woff2"), url("../fonts/GolosText-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	font-family: GolosText;
	font-display: swap;
	src: url("../fonts/GolosText-Semibold.woff2") format("woff2"), url("../fonts/GolosText-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}
