@import "fonts";
@import "search";

@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
// @import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
// @import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
// @import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
// @import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
// @import "../../node_modules/bootstrap/scss/progress";
// @import "../../node_modules/bootstrap/scss/list-group";
// @import "../../node_modules/bootstrap/scss/close";
// @import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
// @import "../../node_modules/bootstrap/scss/popover";
// @import "../../node_modules/bootstrap/scss/carousel";
// @import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
// @import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";

//Fancybox
@import "../../node_modules/@fancyapps/ui/dist/fancybox/fancybox.css";

// Swiper
@import "../../node_modules/swiper/swiper.scss";

body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none !important;
  appearance: none;
  box-shadow: none !important;
}

.devlogo {
  img {
    width: 40px;
    height: 40px;
  }

  span {
    font-family: "devlogo";
    font-size: 30px;
    line-height: 1em;
    text-transform: uppercase;
  }

  a {
    font-family: "Arial";
    font-size: 11px;
    line-height: 1em;

    &:hover {
      color: $link-color !important;
    }
  }
}

.scroll-to-top {
  bottom: 1rem;
  right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  z-index: 2;
  background-color: $primary;
  border-radius: $border-radius-lg;
  visibility: hidden;

  svg {
    width: 50%;
    height: auto;

    path {
      fill: $white;
    }
  }
}

.cookie-alert {
  position: fixed;
  bottom: 1rem;
  z-index: 11;
  margin: 1rem 1rem 0 1rem;
  max-width: 570px;
}

.article {
  font-size: 17px;

  h1 {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.35;
  }

  p,
  ul,
  ol {
    max-width: 720px;
    margin-bottom: 1.5rem;
    line-height: 1.55;
    margin-left: auto;
    margin-right: auto;
  }

  ul,
  ol {
    li {
      margin-bottom: 0.5rem;
    }

    ul,
    ol {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .caption {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;
    padding-top: 0.25rem;
    font-size: 0.875rem;
  }

  table {
    margin-bottom: 2rem !important;
    @include font-size(0.875rem);

    caption {
      font-size: 0.875rem;
    }
  }

  .document,
  .accordion,
  .link-startpoint {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  &-main {
    font-size: 1rem;

    h1 {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      font-size: $h2-font-size;
      margin-bottom: 1rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    p,
    ul,
    ol {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.fit-to-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}

.news-item-date {
  background-color: #e6e6e7;
}

.ya-share2 {
  ul {
    margin-bottom: 0 !important;
  }
}

ul {
  li {
    &::marker {
      color: $primary;
    }
  }
}

.fw-medium {
  font-weight: 500;
}

.caret,
.icon {
  flex-shrink: 0;

  svg {
    path {
      transition: fill 0.15s ease-in-out;
    }
  }
}

.dropdown-toggle,
.submenu-toggle {
  display: flex;

  .caret {
    svg {
      height: 4px;
      width: auto;
    }
  }

  &:hover {
    .caret {
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }
}

.dropdown-menu {
  box-shadow: $box-shadow;
}

.dropdown-menu.show {
  margin-top: 0.5rem;
  animation-name: FadeIn;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

@keyframes FadeIn {
  0% {
    margin-top: 1rem;
    opacity: 0;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    margin-top: 0.5rem;
    opacity: 1;
  }
}

.dropdown-item {
  display: flex;
  transition: color 0.15s ease-in-out;

  .icon {
    margin-right: 0.5rem;
    line-height: 1.3em;
    text-align: center;

    svg {
      width: 14px;
      height: 14px;

      path {
        transition: fill 0.15s ease-in-out;
      }
    }
  }

  .indicator {
    margin-top: 9px;
  }

  &:hover,
  &.active {
    .icon {
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }
}

.accordion {
  &-title {
    transition: color 0.15s ease-in-out;

    .caret {
      svg {
        height: 6px;
        width: auto;
        transition: transform 0.15s ease-in-out;
      }
    }

    &:hover {
      .caret {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }

  &.active {
    .accordion {
      &-title {
        .caret {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

a[data-fancybox] {
  &:hover {
    cursor: zoom-in;
  }
}

.form-check {
  &-input {
    &:hover {
      cursor: pointer;
    }
  }

  .verified,
  .unverified,
  .primary {
    color: $gray-700;
    white-space: nowrap;

    .icon {
      svg {
        height: 14px;
        width: auto;
        margin-bottom: 2px;
      }
    }
  }

  .verified {
    .icon {
      svg {
        path {
          fill: $blue;
        }
      }
    }
  }

  .unverified {
    .icon {
      svg {
        path {
          fill: $danger;
        }
      }
    }
  }

  .primary {
    .icon {
      svg {
        path {
          fill: $warning;
        }
      }
    }
  }
}

.link-white {
  color: $white;
  &:hover {
    color: $link-color !important;
  }
}

.link-dark {
  transition: color 0.15s ease-in-out;

  &:hover {
    color: $link-color !important;
  }

  &.link-icon {
    &:hover {
      .icon {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}

.link-icon {
  transition: color 0.15s ease-in-out;

  .icon {
    line-height: 0;

    svg {
      height: 18px;
      width: auto;

      path {
        transition: fill 0.15s ease-in-out;
      }
    }
  }
}

.link-login {
  .text-truncate {
    max-width: 180px;
  }
}

.link-startpoint {
  font-size: 1rem;

  .icon {
    line-height: 0;

    svg {
      height: 14px;
      width: auto;

      path {
        fill: $primary;
        transition: fill 0.15s ease-in-out;
      }
    }
  }
}

.btn-icon {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .icon {
    line-height: 0;

    svg {
      height: 18px;
      width: auto;

      path {
        transition: fill 0.15s ease-in-out;
      }
    }
  }
}

.btn-square {
  padding: 0;
  height: 2.875rem;
  width: 2.875rem;
}

.btn-primary,
.btn-dark {
  .icon {
    svg {
      path {
        fill: $white;
      }
    }
  }
}

.btn-outline-dark {
  &:hover {
    svg {
      path {
        fill: $white;
      }
    }
  }
}

.pagination {
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  .page-item {
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    .page-link {
      padding-left: 0.125rem;
      padding-right: 0.125rem;
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;

      .icon {
        line-height: 0.9;

        svg {
          height: 10px;
          width: auto;

          path {
            fill: $dark;
          }
        }
      }
    }
  }
}

.search {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;

  &-close {
    display: none;
  }

  .search-box {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  }

  .search-toggle {
    box-sizing: content-box;
    background: transparent;
    border: 0;
    padding: 0;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      line-height: 0;

      svg {
        height: 18px;
        width: auto;

        path {
          fill: $dark;
          transition: fill 0.15s ease-in-out;
        }
      }
    }

    &:hover {
      span {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }

  &.active {
    width: 100%;

    .search {
      &-box {
        opacity: 1;
        transform: translateY(0);
      }

      &-open {
        display: none;
      }

      &-close {
        display: inherit;
      }
    }
  }
}

.search-box {
  position: relative;

  .form-control {
    padding-right: 5rem;
  }

  .btn-close,
  .btn-search {
    position: absolute;
    top: 4px;
    bottom: 4px;
    padding: 0;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      line-height: 0;

      svg {
        height: 1rem;
        width: auto;
      }
    }
  }

  .btn-close {
    right: 44px;
    height: auto;

    .icon {
      svg {
        height: 12px;

        path {
          fill: $gray-600;
          transition: fill 0.15s ease-in-out;
        }
      }
    }

    &:hover {
      .icon {
        svg {
          path {
            fill: $dark;
          }
        }
      }
    }
  }

  .btn-search {
    right: 4px;
    border-radius: 0.5rem;

    .icon {
      svg {
        width: auto;
      }
    }
  }
}

.sidebar {
  .submenu {
    list-style: none;
  }

  .nav-link {
    color: $body-color;
    padding-left: 0;
    padding-right: 0;

    &:hover,
    &.active {
      color: $primary;
    }
  }

  .nav-item {
    transition: padding-bottom 0.15s ease;

    &.active {
      padding-bottom: 0.5rem;

      & > .nav-link,
      & > .nav-link-wrap > .nav-link {
        color: $primary;

        .icon,
        .caret {
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
  }
}

.offcanvas {
  .sidebar {
    .submenu {
      li {
        .nav-link {
          font-size: 14px;
        }

        &:first-of-type {
          padding-top: 0.5rem;
        }

        &:last-of-type {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}

.img {
  &-image {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transition: transform 0.15s ease-in-out;
  }

  &-zoom {
    &:hover {
      .img {
        &-image {
          transform: scale(1.05);
        }
      }
    }
  }
}

.btn-back,
.btn-close,
.btn-map-menu-toggle {
  box-sizing: content-box;
  width: $btn-close-width;
  height: $btn-close-height;
  padding: $btn-close-padding-y $btn-close-padding-x;
  color: $btn-close-color;
  background: transparent;
  border: 0;

  .icon {
    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &:hover {
    text-decoration: none;

    .icon {
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }

  &:focus {
    outline: 0;
    box-shadow: $btn-close-focus-shadow;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
  }
}

.logo {
  img {
    width: auto;
    height: 46px;
  }

  a {
    font-size: 15px;
  }
}

.navbar-nav {
  .nav-item {
    .nav-link {
      padding-top: 11px;
      padding-bottom: 11px;

      &:hover,
      &.active,
      &:focus {
        .caret {
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }

    &:first-of-type {
      .nav-link {
        padding-left: 0;
      }
    }
  }
}

.navbar-toggler {
  --#{$prefix}navbar-color: #{$navbar-light-color};
  --#{$prefix}navbar-toggler-padding-y: 5px;
  --#{$prefix}navbar-toggler-padding-x: 5px;
  --#{$prefix}navbar-toggler-font-size: #{$navbar-toggler-font-size};
  --#{$prefix}navbar-toggler-icon-bg: #{escape-svg($navbar-light-toggler-icon-bg)};
  --#{$prefix}navbar-toggler-focus-width: #{$navbar-toggler-focus-width};
  --#{$prefix}navbar-toggler-transition: #{$navbar-toggler-transition};
  border: none;
  margin-right: -5px;
}

.breadcrumb {
  &-item {
    a {
      color: $dark;
      transition: color 0.15s ease-in-out;

      &:hover {
        color: $primary;
      }
    }
  }
}

.swiper {
  &-button-lock,
  &-pagination-lock {
    opacity: 0 !important;
  }

  &-button-prev,
  &-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $dark;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    position: relative;

    svg {
      height: 14px;

      path {
        transition: fill 0.15s ease-in-out;
        fill: $dark;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $dark;

      svg {
        path {
          fill: $light;
        }
      }
    }
  }

  &-button-prev {
    margin-right: 0.5rem;
  }

  &-pagination-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid $light;
    margin-right: 8px;

    &-active {
      background-color: $light;
      border-color: $light;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.main-slider,
.links-slider .swiper,
.partners-slider {
  .swiper-slide {
    height: auto;
  }
}

.main-slider {
  .swiper-slide {
    min-height: 460px;
  }

  &-nav {
    z-index: 3;
    bottom: 1rem;
    right: 1rem;
  }
}

.sbn-1,
.sbp-1 {
  border: 1px solid $light;
  background-color: transparent;

  svg {
    path {
      fill: $light;
    }
  }

  &:hover {
    background-color: $light;

    svg {
      path {
        fill: $dark;
      }
    }
  }
}

.main-slide {
  .img {
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.6) 100%);
    }
  }

  &-caption {
    z-index: 2;
    max-width: 80%;
  }

  &-title {
    font-size: 32px;
  }

  &-text {
    font-size: 17px;
  }
}

.vector-map {
  svg {
    width: 100%;
    height: auto;

    path {
      transition: fill 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
  }
}

.map-banner {
  p {
    font-size: 14px;
  }

  .btn {
    width: 100%;
  }
}

.more {
  transition: color 0.15s ease-in-out;

  &-icon {
    svg {
      height: 8px;
      width: auto;

      path {
        transition: fill 0.15s ease-in-out;
      }
    }
  }

  &:hover {
    .more {
      &-icon {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}

.card {
  &::after {
    content: "";
    position: absolute;
    transition: opacity 0.15s ease-in-out;
    z-index: -1;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    background-color: $dark;
    filter: blur(1rem);
    opacity: 0;
  }

  &:hover {
    &::after {
      opacity: 0.25;
    }
  }

  &.accordion {
    .accordion {
      &-title {
        font-size: 1.125rem;
      }

      &-body {
        font-size: 1rem;

        p,
        ul,
        ol {
          margin-bottom: 1.2em;
        }

        ul,
        ol {
          li {
            margin-bottom: 0.5em;
          }

          ul,
          ol {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }
}

.news-card {
  min-height: 264px;

  &-title {
    transition: color 0.15s ease-in-out;
    word-break: break-word;
    hyphens: auto;
  }

  &:hover {
    .img {
      &-image {
        transform: scale(1.05);
      }
    }
  }

  &-lg {
    .stretched-link {
      &::after {
        z-index: 3;
      }
    }

    .news-card {
      &-header {
        z-index: 2;

        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgb(0, 0, 0);
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 30%, rgba(0, 0, 0, 0) 100%);
        }
      }

      &-title {
        color: $white;
        font-size: 22px;
      }
    }

    .img {
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 0.15s ease-in-out;
      }
    }

    &:hover {
      .news-card {
        &-title {
          color: $primary;
        }
      }

      .img {
        &::after {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.link-card {
  &-title {
    font-size: 1.125rem;
    transition: color 0.15s ease-in-out;
  }

  &-text {
    font-size: 13px;
    line-height: 1.5;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .more {
    font-size: 14px;
  }

  &-icon {
    line-height: 0.75em;

    img {
      height: 40px;
      width: auto;
    }
  }

  &:hover {
    .more {
      &-icon {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}

.quote {
  background-image: url("../img/quote-bg.svg");
  background-position: right 1rem bottom;
  background-size: 128px;
  background-repeat: no-repeat;

  &-name {
    font-size: 20px;
  }

  &-job {
    font-size: 15px;
  }

  &-source {
    font-size: 14px;
  }
}

.links-slide,
.partners-slide {
  img {
    max-width: 100%;
    max-height: 48px;
  }
}

.document {
  font-size: 1rem;

  &-body {
    .stretched-link {
      transition: color 0.15s ease-in-out;
    }

    .icon {
      svg {
        path {
          fill: $primary;
        }
      }
    }

    &:hover {
      .icon {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }

  .icon {
    margin-top: -1px;

    svg {
      height: 22px;
      width: auto;
      path {
        transition: fill 0.15s ease-in-out;
      }
    }
  }

  &-download {
    padding: 8px;
    margin: -8px -8px -8px 0;

    &:hover {
      .icon {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}

.address {
  margin-bottom: 0;

  li {
    margin-bottom: 16px;
  }

  &-title {
    font-size: 13px;
    margin-bottom: 2px;
  }
}

.contacts-map {
  min-height: 400px;
}

.specialist {
  &-img {
    .ratio {
      padding-top: 150%;
    }

    .img-image {
      object-position: 50% 0%;
    }
  }

  &-job {
    font-size: 14px;
    line-height: 1.3em;
  }

  &-contact {
    margin-bottom: 0;

    li {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-title {
      font-size: 13px;
      margin-bottom: 2px;
    }
  }
}

.tab-link {
  transition: color 0.15s ease-in-out;

  &.active {
    color: $primary !important;
  }
}

// .badge {
//   text-align: center;
//   font-size: 10px;
//   padding: 0 5px;
//   min-width: 16px;
//   height: 16px;
//   line-height: 16px;
// }

.indicator {
  flex-shrink: 0;
  line-height: 0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: $danger;
}

.alert {
  font-size: 14px;

  &-danger {
    .btn-close {
      &:hover {
        svg {
          path {
            fill: $danger;
          }
        }
      }
    }
  }

  &-info {
    .btn-close {
      &:hover {
        svg {
          path {
            fill: $info;
          }
        }
      }
    }
  }

  &-success {
    .btn-close {
      &:hover {
        svg {
          path {
            fill: $success;
          }
        }
      }
    }
  }
}

.btn {
  &.has-response {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: -4px;
      top: -4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $danger;
      box-shadow: 0 0 0 2px $white;
    }
  }
}

.request {
  &-object-title {
    transition: color 0.15s ease-in-out;
    font-size: 18px;
    font-weight: 600;
  }

  &-object-type {
    .icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: $border-radius-sm;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 18px;
        height: auto;
      }
    }

    .title {
      font-size: 14px;
      line-height: 1.4em;
    }
  }

  &-object-address {
    .icon {
      flex-shrink: 0;

      svg {
        height: 16px;
        width: auto;
      }
    }

    .title {
      font-size: 14px;
      padding-top: 3px;
    }
  }

  &-object-functions {
    width: 200px;
    border-left: 1px solid $gray-200;
  }

  &-create-title {
    font-size: 12px;
  }

  &-create-date {
    font-size: 13px;
  }

  &-status {
    font-size: 12px;
    padding: 0 6px 1px 6px;

    &.active {
      background-color: $primary;
      color: $white;
    }

    &.closed {
      background-color: $gray-300;
      color: $gray-700;
    }
  }
}

.toasts {
  width: 320px;

  .alert {
    width: 100%;
  }
}

.success-modal-icon {
  width: 48px;
  height: auto;
}

.chat {
  &-subject {
    &-type {
      font-size: 13px;

      .icon {
        border-radius: $border-radius-sm;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: auto;
        }
      }
    }

    &-address {
      font-size: 13px;

      .icon {
        svg {
          width: auto;
          height: 12px;

          path {
            fill: $gray-700;
          }
        }
      }

      .title {
        margin-top: 1px;
        color: $gray-700;
      }
    }

    &-info {
      border-left: 1px solid $gray-200;

      &-title {
        font-size: 12px;
      }

      &-date {
        font-size: 13px;
      }
    }
  }

  &-body {
    max-width: 696px;
    margin: 0 auto;
    padding-bottom: 68px;
  }

  .message {
    position: relative;

    &-header {
      font-size: 14px;
    }

    p {
      margin: 0;
    }

    .fs-10 {
      opacity: 0.75;
    }

    &-admin {
      background-color: $gray-200;

      &::before {
        content: "";
        position: absolute;
        top: 6px;
        left: -8px;
        border-top: 16px solid $gray-200;
        border-left: 14px solid transparent;
      }
    }

    &-user {
      background-color: $primary;
      color: $white;

      &::before {
        content: "";
        position: absolute;
        bottom: 6px;
        right: -8px;
        border-bottom: 16px solid $primary;
        border-right: 14px solid transparent;
      }
    }
  }

  &-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  &-input {
    max-width: 728px;
    margin: 0 auto;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    &-editor {
      display: block;
      width: 100%;
      height: 28px;
      resize: none;
      appearance: none;
      margin: 0;
      font-family: inherit;
      overflow-y: hidden;
      max-height: 92px;
      border: 1px solid $white;
    }
  }

  &-send {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    .icon {
      svg {
        height: 24px;
        width: auto;

        path {
          fill: $primary;
        }
      }
    }

    &:hover {
      .icon {
        svg {
          path {
            fill: darken($primary, 5%);
          }
        }
      }
    }
  }
}

.error-code {
  font-size: 10rem;
}

.social-link {
  width: 24px;
  height: 24px;
  background-color: $dark;
  border-radius: $border-radius-sm;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: $white;
    }
  }

  &:hover {
    background-color: lighten($dark, 10%);
  }
}

.footer {
  background-color: darken($gray-100, 1%);

  &-contact {
    &-title {
      font-size: 12px;
    }

    &-value {
      font-size: 15px;
    }
  }

  &-links {
    font-size: 15px;
  }

  a {
    transition: color 0.15s ease-in-out;
  }

  &-law {
    li {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.plate {
  height: 100%;
  position: relative;
  // z-index: 0;

  ul,
  h5 {
    position: relative;
    z-index: 2;
  }

  ul {
    padding: 0;
    list-style-position: inside;

    li {
      font-size: 14px;
      color: $gray-700;
      text-indent: -1rem;
      padding-left: 1rem;

      span {
        white-space: nowrap;
        font-weight: 500;
        color: $dark;
      }
    }
  }

  .img {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 1rem;
    bottom: 1rem;
    right: 0;
    overflow: hidden;

    img {
      height: 100%;
      max-height: 128px;
      transform: translateX(50%);
    }
  }

  &-md {
  }

  &-lg {
    p {
      margin: 0;
    }
  }

  &-sign {
    display: flex;

    img {
      margin-right: 1.5rem;
    }

    p {
      font-size: 20px;
      margin: 0;

      span {
        white-space: nowrap;
        font-weight: 700;
      }
    }
  }
}

.about {
  &-img {
    .ratio {
      padding-top: 125%;
    }
  }
}

.object {
  &-type {
    .icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: $border-radius-sm;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 18px;
        height: auto;
      }
    }

    .title {
      font-size: 14px;
      line-height: 1.4em;
    }
  }

  &-title {
    font-size: 24px;
  }

  &-info {
    list-style: none;
    padding: 0;
    margin: 0;

    .title {
      font-size: 14px;
      margin-bottom: 4px;
    }

    li {
      margin-bottom: 18px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &-document {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .icon {
      margin-top: -2px;

      svg {
        height: 18px;
      }
    }

    .document {
      &-download {
        padding: 0 !important;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }

  &-map {
    height: 500px;
  }
}

.btn-filters {
  flex-shrink: 0;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  line-height: 0;

  .icon {
    line-height: 0;
    display: block;

    svg {
      height: auto;
      width: 26px;

      path {
        fill: $gray-700;
      }
    }
  }
}

.staff {
  .img {
    width: 75%;
  }

  &-name {
    font-weight: 600;
    line-height: 1.35em;
    margin-top: 0 !important;
  }

  &-job {
    font-size: 13px;
    line-height: 1.5em;
  }

  &-contact {
    display: inline-block;
    font-size: 14px;
    line-height: 1.5em;
  }
}

.found {
  font-size: 14px;
}

.search-item {
  a {
    font-size: 18px;
    transition: color 0.15s ease-in-out;
  }

  p {
    font-size: 15px;
    color: $gray-800;
  }

  em {
    color: $primary;
    font-style: normal;
    font-weight: 700;
  }
}

.pdf {
  padding: 40px 60px;

  &-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: $border-radius-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-right: 12px;

    img {
      width: 20px;
      height: auto;
    }
  }

  &-title {
    font-size: 14px;
    font-weight: 500;
  }

  &-date {
    font-size: 12px;
    color: $gray-700;
  }

  h1 {
    font-size: 20px;
    line-height: 1.5em;
    margin-bottom: 0;
  }

  &-ul {
    display: table;
    width: 100%;
    list-style: none;
    padding: 0;
    margin-bottom: 2.5rem;
  }

  &-li {
    font-size: 14px;
    display: table-row;
    background-color: $gray-200;

    .title,
    .value {
      display: table-cell;
      line-height: 1.5em;
      max-width: 50%;
      padding: 8px 10px;
    }

    .title {
    }

    &:nth-of-type(2n) {
      background-color: $white;
    }
  }
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: end;
  width: 100%;
  padding: 1rem;
  background-color: $gray-100;
  border-radius: $border-radius;
  border: 2px dashed $gray-300;

  .icon {
    flex-shrink: 0;

    svg {
      height: 56px;
      width: auto;

      path {
        fill: $gray-400;
      }
    }
  }
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.file-input:focus {
  outline: none;
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: start;
    margin-bottom: 6px;
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 1.35;
    border-bottom: 1px solid $gray-200;

    .remove {
      flex-shrink: 0;
      margin-right: 8px;
      margin-top: -1px;
      cursor: pointer;

      svg {
        width: 10px;
        height: 10px;

        path {
          fill: $gray-500;
        }
      }

      &:hover {
        svg {
          path {
            fill: $danger;
          }
        }
      }
    }
  }
}

.content-feature {
  .img {
    width: 44px;
    height: 44px;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
  }
}

.feature-line {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .img {
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    margin-top: 4px;
  }

  p {
    margin: auto 0 !important;
    font-size: 16px;
    line-height: 1.5;
  }
}

@include media-breakpoint-down(xxl) {
  .error-code {
    font-size: 8rem;
  }

  .content-feature {
    p {
      font-size: 15px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }

  .error-code {
    font-size: 7rem;
  }

  .map-banner {
    .btn {
      width: auto;
    }
  }

  .vector-map {
    svg {
      width: 164px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .article {
    font-size: 1rem;
  }

  table {
    & > :not(caption) > * > * {
      padding: 6px !important;
    }
  }

  .main-slider {
    .swiper-slide {
      min-height: 420px;
    }
  }

  .main-slide {
    &-caption {
      max-width: 100%;
    }

    &-title {
      font-size: 26px;
    }

    &-text {
      font-size: 16px;
    }
  }

  .quote {
    background-position: right bottom;
    background-size: 64px;

    &-name {
      font-size: 18px;
    }

    &-job {
      font-size: 14px;
    }

    &-text {
      font-size: 14px;
    }

    &-source {
      font-size: 13px;
    }
  }

  .news-card {
    &-lg {
      min-height: 320px;
    }
  }

  .footer {
    background-color: darken($gray-100, 1%);

    &-contact {
      &-title {
        font-size: 11px;
      }

      &-value {
        font-size: 14px;
      }
    }

    &-links {
      font-size: 14px;
    }
  }

  .about {
    &-img {
      .ratio {
        padding-top: 56.25%;
      }
    }
  }

  .object {
    &-title {
      font-size: 20px;
    }

    &-info {
      .title {
        font-size: 12px;
      }

      .value {
        font-size: 14px;
      }

      li {
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &-document {
      font-size: 14px;
    }
  }

  .staff {
    .img {
      width: 75%;
    }

    &-name {
      font-size: 16px;
    }

    &-job {
      font-size: 12px;
    }

    &-contact {
      font-size: 13px;
    }
  }

  .search-item {
    a {
      font-size: 17px;
    }

    p {
      font-size: 14px;
    }
  }
}

@include media-breakpoint-up(md) {
  .request {
    &-object-functions {
      .btn {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .article {
    h1 {
      margin-bottom: 1.25rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0.75rem;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 19px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 17px;
    }

    h6 {
      font-size: 16px;
    }

    p,
    ul,
    ol {
      margin-bottom: 1.25rem;
      line-height: 1.5;
    }

    .caption {
      font-size: 12px;
    }
  }

  table {
    margin-bottom: 1.25rem !important;
    @include font-size(0.75rem);

    & > :not(caption) > * > * {
      padding: 0.25rem !important;
    }

    caption {
      font-size: 12px !important;
    }
  }

  .document {
    @include font-size(0.875rem);
  }

  .link-startpoint {
    font-size: 0.875rem;
    line-height: 1.4em;

    .icon {
      svg {
        height: 0.75rem;
      }
    }
  }

  .card {
    &.accordion {
      .accordion {
        &-title {
          @include font-size(1.125rem);
          line-height: 1.35em;
        }

        &-body {
          @include font-size(0.875rem);
        }
      }
    }
  }

  .link-card {
    &-title {
      font-size: 1rem;
      line-height: 1.4em;
    }

    &-text {
      font-size: 0.75rem;
    }

    .more {
      font-size: 0.75rem;
    }

    &-icon {
      img {
        height: 32px;
      }
    }
  }

  .breadcrumb {
    font-size: 0.75rem;
  }

  .news-card {
    min-height: 230px;

    &-title {
      font-size: 14px;
    }

    &-lg {
      min-height: 280px;

      .news-card {
        &-title {
          font-size: 18px;
        }
      }
    }
  }

  .specialist {
    &-job {
      font-size: 13px;
    }

    &-contact {
      &-title {
        font-size: 12px;
      }

      &-value {
        font-size: 14px;
      }
    }
  }

  .request {
    &-object-functions {
      width: 100%;
      border-left: none;
      border-top: 1px solid $gray-200;
    }

    &-object-title {
      font-size: 16px;
    }
  }

  .chat {
    &-subject {
      &-type {
        font-size: 12px;

        .icon {
          width: 16px;
          height: 16px;
          img {
            width: 14px;
          }
        }
      }

      &-address {
        font-size: 12px;

        .icon {
          svg {
            height: 11px;
          }
        }
      }

      &-info {
        border-left: none;
        border-top: 1px solid $gray-200;

        &-title {
          font-size: 12px;
        }

        &-date {
          font-size: 13px;
        }
      }
    }

    .message {
      p {
        @include font-size(0.875rem);
      }
    }
  }

  .error-code {
    font-size: 6rem;
  }

  .error-text {
    font-size: 14px;
  }

  .map-banner {
    h5 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }

  .main-slider,
  .links-slider .swiper,
  .partners-slider {
    overflow: visible;
  }

  .main-slider {
    .swiper-slide {
      overflow: hidden;
      border-radius: $border-radius;
    }

    .swiper-slide {
      min-height: 380px;
    }
  }

  .main-slide {
    &-title {
      font-size: 24px;
    }
  }

  .quote {
    .img {
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }

    &-name {
      font-size: 18px;
      text-align: center;
    }

    &-job {
      font-size: 14px;
      text-align: center;
    }

    &-text {
      font-size: 14px;
      text-align: justify;
    }

    &-source {
      font-size: 13px;
    }
  }

  .found {
    font-size: 13px;
  }

  .search-item {
    a {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }

  .content-feature {
    .img {
      width: 40px;
      height: 40px;
    }

    p {
      font-size: 14px;
    }
  }

  .feature-line {
    p {
      font-size: 15px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .breadcrumb {
    font-size: 0.625rem;
  }

  table {
    margin-bottom: 1.5rem;
    @include font-size(0.75rem);

    & > :not(caption) > * > * {
      padding: 2px !important;
    }
  }

  .scroll-to-top {
    display: none !important;
  }

  .logo {
    img {
      width: auto;
      height: 32px;
    }

    a {
      font-size: 13px;
    }
  }

  .news-card {
    min-height: 0;

    &-title {
      font-size: 15px;
    }

    &-lg {
      min-height: 280px;
    }
  }

  .pagination {
    .page-item {
      .page-link {
        min-width: 35px;
        height: 35px;
        font-size: 0.75rem;

        .icon {
          svg {
            height: 0.5rem;
          }
        }
      }
    }
  }

  .specialist {
    &-img {
      .ratio {
        padding-top: 120%;
      }
    }

    &-contact {
      li {
        margin-bottom: 14px;
      }

      &-title {
        font-size: 12px;
        margin-bottom: 1px;
      }
    }
  }

  .request {
    &-object-type {
      .icon {
        width: 18px;
        height: 18px;

        img {
          width: 14px;
        }
      }

      .title {
        font-size: 12px;
        line-height: 1.5em;
      }
    }

    &-object-address {
      .icon {
        svg {
          height: 14px;
        }
      }

      .title {
        font-size: 12px;
        padding-top: 5px;
      }
    }

    &-create-title {
      font-size: 12px;
    }

    &-create-date {
      font-size: 13px;
    }
  }

  .toasts {
    width: 100%;
  }

  .chat {
    &-input {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .map-banner {
    .btn {
      width: 100%;
    }
  }

  .vector-map {
    svg {
      width: 75%;
    }
  }

  .main-slider {
    .swiper-slide {
      min-height: 360px;
    }
  }

  .object {
    &-title {
      font-size: 18px;
    }

    &-map {
      height: 400px;
    }
  }

  .content-feature {
    .img {
      width: 32px;
      height: 32px;
    }

    p {
      font-size: 12px;
    }
  }
}
